import { useEffect, useState } from "react";
import { FetchChecklistDokumenVendor } from "../../modules/store/actions/procurement/VendorAction";
import { TableDokumen } from "./TableDokumen";

export const DashboardVendor = (props:any) => {
  const [loading, setloading] = useState<boolean>(true)
  const [data, setData] = useState<any>([])

  useEffect(() => {
    FetchChecklistDokumenVendor({company_id: JSON.parse(localStorage.user).base_perusahaan_id})
      .then((resp:any) => {
        const dt = resp.data.data
        setData(dt)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setloading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className="col-xxl-12 bg-white">
          <div className="my-8">
            <h1 className="mx-4">Checklist Dokumen</h1>
            <div className="m-4">
              <TableDokumen data={data} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}