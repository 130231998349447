// import clsx from 'clsx'
import {FC, useState, useRef, useCallback, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {
  HeaderUserMenu2,
} from '../../../partials'
import { FilePath } from '../../../../setup/axios/FilePath'; 
import { ShowNotificationCount } from '../../../../app/modules/store/actions/WorkflowAction';
import { useHistory } from 'react-router-dom';
import Pusher from "pusher-js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FetchDokumenExpired } from '../../../../app/modules/store/actions/procurement/VendorAction';
import { encryptData } from '../../../../app/pages/AccessRole';
import { FetchPesertaJoin } from '../../../../app/modules/store/actions/procurement/TenderAction';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { decode } from 'js-base64';
import { Html5QrcodePlugin } from '../../../../app/pages/inventory/transaksi/kartu_stok_phc/sub/Html5QrcodePlugin';

const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  // const {config} = useLayout()
  const [count, setCount] = useState<number>(0)
  // const [loadingCount, setLoadingCount] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const temp_path = (JSON.parse(localStorage.user)?.avatar || null) !== null ? `${FilePath}${JSON.parse(localStorage.user).avatar}` : toAbsoluteUrl('/media/avatars/150-2.jpg')
  const [avatar, setAvatar] = useState(temp_path)
  const [avatarPath, setAvatarPath] = useState(JSON.parse(localStorage.user)?.avatar)

  const handleFocus = (state:boolean) => {
    let isMounted = true
    if (isMounted) {
      changeAvatar()
      setOpen(!state)
    }
    return () => { isMounted = false }
  }

  const handleClickOutside = (e:any) => {
    let isMounted = true
    if (isMounted) {
      if (open && !dropdownRef.current?.contains(e.target as Node)){
        setOpen(false)
      }
    }
    return () => { isMounted = false }
  }

  window.addEventListener('click', handleClickOutside);

  const changeAvatar = useCallback(() => {
    let isMounted = true
    if (isMounted) {
      if (avatarPath !== JSON.parse(localStorage.user)?.avatar) {
        if (JSON.parse(localStorage.user).avatar === null) {
          setAvatar(toAbsoluteUrl('/media/avatars/150-2.jpg'))
        }
        else {
          setAvatarPath(JSON.parse(localStorage.user).avatar)
          setAvatar(`${FilePath}${JSON.parse(localStorage.user).avatar}`)
        }
      }
    }
    return () => { isMounted = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[avatarPath]);


  const customToastWithLink = (params:any) => (
    <div>
      <span><span className='fw-bolder'>{params.vendor_name}</span> join tender <span className='fw-bolder'>{params.tender_name}</span></span> <button className='btn btn-secondary btn-sm' onClick={(e:any)=>handleClick(e, params)}>proses</button>
    </div>
  );

  // FETCH PESERTA JOIN TENDER
  const fetchPesertaJoinTender = useCallback(() => {
    let isMounted = true;
    const params = {
      company_id: 1,
    }
    FetchPesertaJoin(params)
      .then((resp:any) => {
        const dt = resp.data.data
        if (isMounted) {
          dt.forEach((element:any) => {
            toast.info(customToastWithLink(element), {position: toast.POSITION.TOP_RIGHT, autoClose: false});
          });
        }
      })
      .catch((err:any) => {
        console.log(err)
      })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchNotificationCount = (data:any) => {
    const params = {company_id: 1}
    ShowNotificationCount(params)
      .then((res:any) => {
        const dt = res.data.data
        setCount(dt.jumlah_notifikasi)
        toast.info(data?.pesan, {position: toast.POSITION.TOP_RIGHT});
      })
      .catch((err:any) => {
        console.log(err)
      })
      // .finally(() => {
      //   setLoadingCount(false)
      // })
  }

  const fetchDokumenExpired = () => {
    const params = {
      company_id: JSON.parse(localStorage.user).base_perusahaan_id,
    }
    FetchDokumenExpired(params)
      .then((resp:any) => {
        const data = resp.data.data
        let text = data?.info_dokumen_expired || null
        if (text !== null && text !== "") {
          const arr_text = data?.info_dokumen_expired.split("; ")
          text = arr_text.join('\n')
          toast.error(<div style={{whiteSpace: 'pre-wrap'}}>{text}</div>, {position: toast.POSITION.TOP_RIGHT, autoClose: false})
        }
      })
      .catch(err => {
        console.log(err)
        // setLoadingBast(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    fetchNotificationCount({})
    const modul = localStorage.getItem('modul') !== null ? JSON.parse(localStorage.modul) : []
    const index = modul.findIndex((x:any) => x.nama === "Verifikasi Join Tender")
    if (index !== -1) {
      fetchPesertaJoinTender()
    }
    const role = localStorage.getItem('role') !== null ? JSON.parse(localStorage.role) : []
    if (role.some((e:any) => e.nama === 'Vendor')) {
      fetchDokumenExpired()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Notification
  useEffect(() => {
    const pusher = new Pusher("699ce0583b8a7bef7f1d", {
      cluster: "ap1",
      enabledTransports: ["ws", "wss"]
    });
    const channel = pusher.subscribe("realtime-notification");
    channel.bind("approve-notification", (data:any) => {
      const user_id = JSON.parse(localStorage.user)?.id
      // console.log(data)
      if (data?.receiver !== undefined) {
        if (data?.receiver === user_id || data?.next_approval === user_id) {
          fetchNotificationCount(data)
        }
      }
    });
    return () => {
      pusher.unsubscribe("realtime-notification");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Join Tender
  useEffect(() => {
    const modul = localStorage.getItem('modul') !== null ? JSON.parse(localStorage.modul) : []
    const index = modul.findIndex((x:any) => x.nama === "Verifikasi Join Tender")
    if (index !== -1) {
      const pusher = new Pusher("81dc9030bfd62180b123", {
        cluster: "ap1",
        enabledTransports: ["ws", "wss"]
      });
      const channel = pusher.subscribe("tender-notification");
      channel.bind("join-tender", (data:any) => {
        toast.info(customToastWithLink(data), {position: toast.POSITION.TOP_RIGHT, autoClose: false});
        // console.log(JSON.parse(localStorage.user))
        // fetchNotificationCount(data)
      });
      return () => {
        pusher.unsubscribe("realtime-notification");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const redirectToMenu = (e:any) => {
    e.preventDefault();
    history.push({
      pathname: `/tools/approve-dokumen`,
    })
	}

  const handleClick = (e:any, params:any) => {
    e.preventDefault()
    const obj = {
      base_url: params.base_url,
      access_menu: params.access_menu,
      company_id: params.company_id,
      tender_id: params.tender_id,
      vendor_id: params.vendor_id,
    }

    history.push({
      pathname: `${params.base_url}/${encryptData(obj)}`,
    })
  }

  const [toggleModal, setToggleModal] = useState(false)
  const [errorScan, setErrorScan] = useState(false);
  const [togglePause, setTogglePause] = useState(false)

  useEffect(() => {
    if(errorScan) {
      toast.error("QR code tidak bisa diakses", {position: toast.POSITION.TOP_RIGHT, autoClose:2500})
      setTimeout(() => {
        setErrorScan(false)
      }, 2500);
    }
    if(togglePause) {
      toast.error("QR code tidak bisa diakses", {position: toast.POSITION.TOP_RIGHT, autoClose:2500})
      setTimeout(() => {
        setTogglePause(false)
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorScan, togglePause])

  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  // decode scanner
  const handleDecodeResults = (decodedText:any, _decodedResult:any) => {
    // console.log(errorScan)
    if (!errorScan) {
      if (base64regex.test(decodedText) === false) {
        // console.log('error_base64');
        setTogglePause(true)
      }
      else {
        const params_decoded = decode(decodedText)
        const data_params = JSON.parse(params_decoded)
        if (data_params?.company_id !== undefined && data_params?.kode !== undefined && data_params?.id !== undefined) {
          const arrPathName = window.location.pathname.split('/').filter((i) => i === `${process.env.REACT_APP_SITES_URL}`)
          const pathname = window.location.protocol + "//" + window.location.host + (arrPathName.length>0 ? '/' + arrPathName[0] : '') + '/asset/master/inventaris/scan-qrcode/' + decodedText
          // console.log(pathname)
          window.open(pathname, "_blank");
          setToggleModal(false)
        }
        else {
          // console.log('error')
          setTogglePause(true)
        }
      }
    }
  };
  
  return (
    <div>
      <div className='d-flex align-items-stretch flex-shrink-0' style={{marginTop: '10px', float: 'right'}}>
        <div className='d-flex align-items-center flex-shrink-0'>
          <div
            className={'cursor-pointer symbol ' + toolbarUserAvatarHeightClass}
            onClick={(e) => redirectToMenu(e)} style={{float: "right"}}
          >
            <div className='d-flex'>
              <i className='fa fa-bell' style={{fontSize: '28px'}}></i>
              {count > 0 &&
                <div style={{verticalAlign: 'super'}}>
                  <div className='w-auto rounded-circle bg-danger text-center px-1 py-1' style={{fontSize: '10px'}}>
                    <span className='d-inline-block' style={{color: 'white', minWidth: '14px'}}>
                      {count}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='align-items-center' id='kt_header_user_menu_toggle' style={{marginLeft: '20px'}}>
            <div ref={dropdownRef}>
              <div
                className={'cursor-pointer symbol ' + toolbarUserAvatarHeightClass}
                onClick={(e) => handleFocus(open)} style={{float: "right"}}
              >
                <img src={avatar} alt='avatar'/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {open && <HeaderUserMenu2 openModal={setToggleModal} />}
      </div>
      {toggleModal &&
        <Modal isOpen={toggleModal} toggle={() => setToggleModal(!toggleModal)} size="lg">
          <ModalHeader toggle={() => setToggleModal(!toggleModal)}>
            Scan QR
          </ModalHeader>
          <ModalBody>
            {togglePause ? <div className='text-center'><i className="fas fa-spinner fa-pulse"></i></div> :
              <div>
                <Html5QrcodePlugin
                  fps={60}
                  isMobile={false}
                  disableFlip={false}
                  errorScan={errorScan}
                  setDecodedResults={handleDecodeResults}
                />
              </div>              
            }
          </ModalBody>
        </Modal>
      }
    </div>
  )
}

export {Topbar}
