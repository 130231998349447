import { useMemo } from "react";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";

export const TableDokumen = (props:any) => {
  const {data, loading} = props
  const dataTable = useMemo(()=>data,[data])

  const columns = useMemo(() => [
    {
      accessorKey: 'jenis_dokumen',
      header: 'Jenis Dokumen',
      enableEditing: false
    },
    {
      accessorKey: 'is_ada',
      header: 'Tersedia',
      maxSize: 2,
      Cell: ({ row }) => (
        <input type="checkbox" onChange={()=>{}} checked={row.original.is_ada === 1}/>
      ),
    },
    {
      accessorKey: 'is_sesuai',
      header: 'Status',
      maxSize: 2,
      Cell: ({ row }) => (
        row.original.is_sesuai === 1 ? <span className="rounded bg-success p-2 text-white">Approved</span> :
        row.original.is_sesuai === 0 ? <span className="rounded bg-danger p-2 text-white">Rejected</span> : ''
      ),
    },
    {
      accessorKey: 'catatan',
      header: 'Catatan',
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ] as MRT_ColumnDef<typeof dataTable[0]>[],[]);

  return (
    <MaterialReactTable
      columns={columns}
      data={dataTable}
      enablePagination={false}
      enablePinning
      // enableColumnFilters={false}
      enableColumnActions={false}
      initialState={{ showColumnFilters: true}}
      muiTableHeadCellProps={{
        align: 'center',
        sx: {
          fontSize: '14px',
          '& hr': {
            height: '18px', borderWidth: '2px'
          }, border: '1px solid rgba(81, 81, 81, .1)'
        }
      }}
      muiTableBodyCellProps={{ align: 'center', sx: { fontSize: '14px', border: '1px solid rgba(81, 81, 81, .1)'} }}
      muiBottomToolbarProps={{
        sx:{zIndex: 0},
      }}
      state={{ isLoading: loading }}
      enableTopToolbar={false}
    />
  );
}
